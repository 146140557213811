export const templateConsultaIntegracionTokko = (data) => {
    return `
        <!DOCTYPE html>
        <html>
        <head>
                <meta http-equiv="Content-Type" content="text/html;" charset="UTF-8" />
            <title>Consulta por la Integración con Tokko</title>
            <style type="text/css">
                body{
                            background-color: #f7f3f2;
                            margin:0;
                        }
                        #bodyTable{}
                        h1{
                            font:14px/21px verdana, sans-serif;
                            color:#323232;
                        }
                        p{
                            font:14px/24px verdana, sans-serif;
                            color:#7f7f7f;
                            margin:0;
                        }
                        .access_data{
                            border:solid 1px #b2b2b2;
                            font:14px/24px verdana, sans-serif;
                            color:#7f7f7f;
                        }
                        .btn{
                            font:16px/20px verdana, sans-serif;
                            color:#fff;
                            text-align: center;
                            width:200px;
                            margin:auto;
                            background-color: #19b14b;
                            padding:10px;
                            text-decoration: none;
                        }
                        .link{
                            color:#0d72b9;
                            font:14px/24px verdana, sans-serif;
                            text-decoration: none;
                        }
                        .alentar-table {
                            background: url('https://filesinmoclick.s3.amazonaws.com/img_varias/email_app/banner-app_v2.jpg') no-repeat center;
                            background-size: contain;
                            width: 100%;
                        }
                        .cln1 {
                            width: 340px;
                        }
                        .cln2 {
                            width: 120px;
                        }
                        .cln3 {
                            width: 120px;
                        }
                        .cln4 {
                            width: 20px;
                        }
                        .btn-descarga {
                            width: 113px;
                            z-index: 10;
                        }
                        .btn-descarga img {
                            width: 100%;
                        }
        
                        @media only screen and (max-width:600px){
                            #emailContainer{
                                width:90% !important;
                            }
                            .cln1 {
                                width: 57%;
                            }
                            .cln2 {
                                width: 20%;
                            }
                            .cln3 {
                                width: 20%;
                            }
                            .cln4 {
                                width: 3%;
                            }
                            .btn-descarga {
                                width: 94%;
                            }
                        }
            </style>
        </head>    
        <body>
            <table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable">
                    <tr>
                        <td align="center" valign="top">
                            <table border="0" cellpadding="0" cellspacing="0" width="600" id="emailContainer">
                                <tr>
                                    <td align="center" valign="top">
                                        <table border="0" cellpadding="0" cellspacing="0" width="100%" id="emailHeader">
                                            <tr>
                                                <td bgcolor="#d68428" style="padding:5px"></td>
                                                <td bgcolor="#c42c32" style="padding:5px"></td>
                                                <td bgcolor="#676768" style="padding:5px"></td>
                                                <td bgcolor="#808034" style="padding:5px"></td>
                                            </tr>
                                            <tr>
                                            <td align="center" valign="middle" colspan="4" bgcolor="#7E7A80" cellpadding="20">
                                                <img src="https://inmoclickfiles-ue1.s3.amazonaws.com/public_images/Images_mailing/grupo_8191.png" alt="Inmoclick" align="center" width="130px" style="padding-right:20px;" /> 
                                                <img src="https://inmoclickfiles-ue1.s3.amazonaws.com/public_images/Images_mailing/logo_tokko_01_1.png" alt="Tokko" align="center" height="60px" style=" padding-top:4px; padding-left:20px;"/> 
                                            </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td align="center" valign="top"> 
                                        <table>	
                                            <tr> 
                                                <td> <p>Razon social: </p> </td>
                                                <td> <p style="color:grey;">${data.razon_social}</p> </td>
                                            </tr>
                                            <tr>
                                                <td> <p>Telefono: </p> </td>
                                                <td> <p style="color:grey;">${data.telefono}</p> </td>
                                            </tr>
                                            <tr>
                                                <td> <p>E-mail: </p> </td>
                                                <td> <p style="color:grey;">${data.email}</p> </td>
                                            </tr>
                                            <tr>
                                                <td> <p>Mensaje: </p> </td>
                                                <td> <p style="color:grey;">${data.mensaje}</p> </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                
                                    <tr>
                                        <td>
                                            <table class="alentar-table">
                                                <tr height="115">
                                                    <td class="cln1"></td>
                                                    <td class="cln2"></td>
                                                    <td class="cln3"></td>
                                                    <td class="cln4"></td>
                                                </tr>
                                                <tr height="33">
                                                    <td class="cln1"></td>
                                                    <td class="cln2">
                                                        <a href="https://play.google.com/store/apps/details?id=com.inmoclick&hl=es_419" class="btn-descarga">
                                                            <img src="https://filesinmoclick.s3.amazonaws.com/img_varias/email_app/btn_google.png" alt=""/>
                                                        </a>
                                                    </td>
                                                    <td class="cln3">
                                                        <a href="https://apps.apple.com/us/app/inmoclick/id1469170693" class="btn-descarga">
                                                            <img src="https://filesinmoclick.s3.amazonaws.com/img_varias/email_app/btn_apple.png" alt=""/>
                                                        </a>
                                                    </td>
                                                    <td class="cln4"></td>
                                                </tr>
                                                <tr height="65">
                                                    <td class="cln1"></td>
                                                    <td class="cln2"></td>
                                                    <td class="cln3"></td>
                                                    <td class="cln4"></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="left" width="100%" style="padding-top: 3px; padding-left: 10px; padding-right: 10px; padding-bottom: 25px; margin-top: 40px;" font-color="#616161" bgcolor="#e6e6e6"; >
                                            <p style="margin: 0; font-family:'Gotham', sans-serif; font-weight: 300; padding:0; padding-top: 40px; margin-left: 40px;">
                                                Atentamente
                                            </p>
                                            <p style="margin-top: 0; font-family:'Gotham', sans-serif; font-weight: 300; padding-top: 10px; margin-left: 40px;">
                                                Equipo Inmoclick
                                            </p>
                                            <p style="margin-top: 0; font-family:'Gotham', sans-serif; font-weight: bold; padding-top: 10px; margin-left: 40px; margin-bottom:30px;">
                                                <a href="https://www.inmoclick.com.ar/" style="text-decoration: none; color:#6D6D6D;"><strong>inmoclick.com.ar</strong></a>
                                            </p>
                                            <hr style="margin:30px;">
                                            <table width="100%">
                                                <th>
                                                    <td align="left" valign="middle" style="background-color: #e6e6e6;">
                                                        <a href="https://www.inmoclick.com.ar/" style="text-decoration:none;"><img src="https://www.inmoclick.com.ar/images_mailing/imgs/logo_bw.png" alt="Logo Inmoclick" width="100" height="26" border="0" style="margin-left: 30px; margin-right:20px;"/></a>
                                                    </td>
                                                </th>
                                                <th>
                                                    <td align="right" valign="middle" style="background-color: #e6e6e6; padding-right: 30px">
                                                        <a href="https://www.instagram.com/inmoclick.com.ar" style="text-decoration:none">
                                                            <img src="{{ absolute_url(asset('email-icon-instagram_gray.png', 'img')) }}" alt="">
                                                        </a> 
                                                        <a href="https://www.facebook.com/inmoclick" style="text-decoration:none; margin-right: 5px">
                                                            <img src="{{ absolute_url(asset('email-icon-facebook_gray.png', 'img')) }}" alt="">
                                                        </a>
                                                    </td>
                                                </th>
                                            </table> 
                                        </td>
                                        <td>
                                            <tr style="display:flex" valign="bottom">
                                                <td width="25%" bgcolor="#D6001D" style="background-color:#D6001D" height="8" valign="bottom"></td>
                                                <td width="25%" bgcolor="#77912E" style="background-color:#77912E" height="8" valign="bottom"></td>
                                                <td width="25%" bgcolor="#6B6A68" style="background-color:#6B6A68" height="8" valign="bottom"></td>
                                                <td width="25%" bgcolor="#E29700" style="background-color:#E29700" height="8" valign="bottom"></td>
                                            </tr>
                                        </td>
                                    </tr> 
                            </table>
                        </td>
                    </tr>
                </table>
        </body>
        </html>
    `
}