export const API = process.env.REACT_APP_URL_API;

const headersConfig = () => {
    return {
        headers: {
            "Content-Type": "text/plain"
        }
    };
}

export const servisConfig = headersConfig();