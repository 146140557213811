import React from "react";
import { BrowserRouter, Route } from "react-router-dom";

import Home from "./components/tokko/Home/Home";

const Routers = () => {
    return (<>
        <BrowserRouter>
            <Route exact path="/" component={Home} />
        </BrowserRouter>
    </>);
}

export default Routers;