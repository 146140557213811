import React from "react";
import { Row, Col } from "react-bootstrap";

import "./Footer.css";

const Footer = () => {
    return(<>
        <Row className="content_footer">
            <Col className="cont_text_footer">
                <p>
                    <b>Inmoclick®</b>2021 Todos los derechos reservados. <br/>
                    <b>Tokko Broker®</b>es una marca perteneciente a Grupo Navent.
                </p>
            </Col>
        </Row>
    </>)
}

export default Footer;