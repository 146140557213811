import React, { useRef, useState } from "react";
import { Row, Col, Form, Button, Image, Tooltip, OverlayTrigger, Spinner } from "react-bootstrap"; //Documentacion https://react-bootstrap.github.io/
import { useForm } from "react-hook-form"; //Documentacion https://react-hook-form.com/
import ReCAPTCHA from "react-google-recaptcha"; //Documentacion https://www.npmjs.com/package/react-google-recaptcha
import { ToastContainer, toast } from 'react-toastify'; //Documentacion en https://fkhadra.github.io/react-toastify/

//Imagenes/Iconos
import icon_calendario from "../../../img/icon_calendario.png";
import icon_cash_register from "../../../img/icon_cash_register.png";
import icon_person from "../../../img/icon_person.png";
import icon_update from "../../../img/icon_update.png";
import img1 from "../../../img/img_sec1.png";
import img2 from "../../../img/Grupo 8196@2x.png";
import wp from "../../../img/componente_32_1.png";

//Import componentes
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

//Import services
import { sendMail } from "../../../services/apiConsultaEmail"

//Import templates 
import { templateConsultaIntegracionTokko } from "../../../templates/emails/ConsultaIntegracionTokko";

//Import css
import "./Home.css";
import 'react-toastify/dist/ReactToastify.css';

const Home = () => {
    const recaptchaRef = useRef(null);
    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const [ showSpinner, setShowSpinner ] = useState(false);

    const onSubmit = async (data, e) => {
        const captchaToken = await recaptchaRef.current.executeAsync();
        setShowSpinner(true);

        if(captchaToken){
            const template = templateConsultaIntegracionTokko(data);

            const enviarApiEmail1 = {
                tokenCaptcha: captchaToken,
                template_mail: template,
                replyto: data.email,
                to_tokko: process.env.REACT_APP_TO_TOKKO,
                to_inmoclick: process.env.REACT_APP_TO_INMOCLICK,
                prioridad: process.env.REACT_APP_PRIORIDAD
            }

            try {
                //Envia mail a tokko y a inmoclick
                const res1 = await sendMail(enviarApiEmail1);
                
                if(res1.data.status != 200){
                    toast.error("Error al enviar el mensaje a Tokko.", {
                        theme: "dark"
                    })
                    setShowSpinner(false);
                }else {
                    toast.success("Mensaje enviado con exito!", {
                        theme: "dark"
                    })
                    setShowSpinner(false);
                    reset();
                }
            } catch (error) {
                console.log(error);
                toast.error("Error al enviar el mensaje.", {
                    theme: "dark"
                })
                setShowSpinner(false);
            }

            recaptchaRef.current.reset();
        }
    }

    return (<>
        <Row className="seccion img_port_fond">
            <Col sm={12}>
                <Navbar />
            </Col>
            <Col sm={6} className="cont_s1_text">
                <h1 className="title_1s">¡Hola!</h1>
                <h3 className="sub_title_1s">TENEMOS BUENAS NOTICIAS</h3>
                <p className="text">
                    Te queremos comunicar que nos hemos integrado con <b>Tokko Broker</b>. El CRM inmobiliario mas potente del mercado.
                    A partir de ahora podrás gestionar tus propiedades con mayor profesionalidad
                </p>
                <a href="#form">
                    <Button variant="outline-light" className="btn_ir_form">Quiero empezar ahora</Button>
                </a>
            </Col>
            <Col sm={6} className="cont_s1_img">
                <Col sm={12}>
                    <Image src={img1} className="img1" />
                </Col>
                <Col sm={12}>
                    <a href="https://api.whatsapp.com/send?phone=5492615182197&text=%C2%A1Hola!%20Quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20la%20integraci%C3%B3n%20con%20Tokko.">
                        <Image src={wp} className="wp" />
                    </a>
                </Col>
            </Col>
        </Row>
        <Row id="beneficios" className="seccion content_seccion_2">
            <Col sm={7}>
                <Image src={img2} className="img2" />
            </Col>
            <Col sm={5}>
                <h3 className="sub_title_2s">TOKKO BROKER</h3>
                <h1 className="title_2s">Beneficios</h1>
                <Col className="text_s2">
                    <p className="text_s2_sub"><b>Por ser parte de Inmoclick</b>, tendrás acceso a: </p>
                    <li>La contratación de un plan preferencial de hasta <b>3 usuarios y 50 propiedades</b> con un costo de <b className="text_remarcado">
                        $2199 + IVA Y 30% de descuento</b> en los primeros 6 meses de contratación de otros planes.</li>
                </Col>
                <a href="https://www.tokkobroker.com/?utm_source=partners&utm_medium=landing&utm_campaign=inmoclick">
                    <Button className="btn_s2">Contratar</Button>
                </a>
            </Col>
        </Row>
        <Row id="caracteristicas" className="seccion">
            <Row>
                <Col className="seccion_3s_text" sm={12}>
                    <h3 className="sub_title_3s">INTEGRADOS FUNCIONAMOS</h3>
                    <h1 className="title_3s">+Rápido</h1>
                    <p className="text_s3">
                        Con esta integración buscamos <b>simplificar</b> aún más la gestión de nuestros usuarios, brindando mayor celeridad en todo el proceso
                        de publicación de avisos y replicación en múltiples portales.
                    </p>
                </Col>
            </Row>
            <Row className="sec_icon_s3">
                <Col>
                    <Row><Image src={icon_calendario} /></Row>
                    <Row><b className="text_icons_s3">Calendario</b></Row>
                </Col>
                <Col>
                    <Row><Image src={icon_person} /></Row>
                    <Row><b className="text_icons_s3">Acceso a clientes</b></Row>

                </Col>
                <Col>
                    <Row><Image src={icon_cash_register} /></Row>
                    <Row><b className="text_icons_s3">Control de cajas</b></Row>
                </Col>
                <Col>
                    <Row><Image src={icon_update} /></Row>
                    <Row><b className="text_icons_s3">Vencimientos y <br />Renovaciones</b></Row>
                </Col>
            </Row>
        </Row>
        <Row id="form" className="seccion img_form_fond">
            <Row className="items_s4">
                <h3 className="sub_title">¿TENÉS ALGUNA DUDA?</h3>
                <h1 className="title">Estamos para ayudarte</h1>
            </Row>
            <Form className="items_s4" onSubmit={handleSubmit(onSubmit)} >
                <Row>
                    <Col sm={6}>
                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm={4} className="desc_form">
                                Razon Social*
                            </Form.Label>
                            <Col sm={8}>
                                <OverlayTrigger placement="bottom" overlay={
                                    errors.razon_social ?
                                        <Tooltip id="tooltip-bottom" className="text_error" type="invalid">
                                            {errors.razon_social.message}
                                        </Tooltip> :
                                        <Tooltip id="tooltip-bottom">
                                            Complete este campo para poder identificarlo
                                        </Tooltip>
                                }>
                                    <Form.Control tabIndex={1} name="razon_social" type="text" className={`input ${errors.razon_social && 'is-invalid' }`}
                                        {...register('razon_social', {
                                            required: {
                                                value: true,
                                                message: "Necesitamos la razon social para poder identificarlo."
                                            }
                                        })}
                                    />
                                </OverlayTrigger>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={4} className="desc_form">
                                E-mail*
                            </Form.Label>
                            <Col sm={8}>
                                <OverlayTrigger placement="bottom" overlay={
                                    errors.email ?
                                        <Tooltip id="tooltip-bottom" className="text_error" type="invalid">
                                            {errors.email.message}
                                        </Tooltip> :
                                        <Tooltip id="tooltip-bottom">
                                            Complete este campo para poder comunicarnos con usted
                                        </Tooltip>
                                }>
                                    <Form.Control tabIndex={3} name="email" type="text" className={`input ${errors.email && 'is-invalid' }`}
                                        {...register('email', {
                                            required: {
                                                value: true,
                                                message: "Necesitamos su e-mail para comunicarnos con usted."
                                            },
                                            pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: "El formato no es correcto"
                                            }
                                        })}
                                    />
                                </OverlayTrigger>
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={4} className="desc_form">
                                Telefono
                            </Form.Label>
                            <Col sm={8}>
                                <OverlayTrigger placement="bottom" overlay={
                                    errors.telefono ?
                                        <Tooltip id="tooltip-bottom" className="text_error" type="invalid">
                                            {errors.telefono.message}
                                        </Tooltip> :
                                        <Tooltip id="tooltip-bottom">
                                            Complete este campo para poder comunicarnos con usted
                                        </Tooltip>
                                }>
                                    <Form.Control tabIndex={2} name="telefono" type="tel" className={`input ${errors.telefono && 'is-invalid' }`}
                                        {...register('telefono', {
                                            pattern: {
                                                value: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
                                                message: "El formato no es correcto"
                                            }
                                        })}
                                    />
                                </OverlayTrigger>
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group as={Row} className="mb-3 cont_text_area_s4">
                            <Form.Label column sm={4} className="desc_form">
                                Mensaje
                            </Form.Label>
                            <Col sm={8}>
                                <OverlayTrigger placement="bottom" overlay={
                                    errors.mensaje ?
                                        <Tooltip id="tooltip-bottom" className="text_error" type="invalid">
                                            {errors.mensaje.message}
                                        </Tooltip> :
                                        <Tooltip id="tooltip-bottom">
                                            Dejenos saber cuál es su consulta.
                                        </Tooltip>
                                }>
                                    <Form.Control tabIndex={4} name="mensaje" as="textarea" type="text" className={`input text_area ${errors.mensaje && 'is-invalid' }`} 
                                        {...register('mensaje')}
                                    />
                                </OverlayTrigger>
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_CAPTCHA_PUBLIC} size="invisible" />
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <Row>
                            <Col sm={4}></Col>
                            <Col sm={8}>
                                <Button type="submit" className="btn_form" disabled={showSpinner}>
                                    { !showSpinner ? 
                                        'Enviar'
                                    :<>
                                        Enviando 
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    </>}
                                    
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={6}></Col>
                </Row>
            </Form>
            <ToastContainer autoClose={6000} />
        </Row>
        <Footer />
    </>)
}

export default Home;
