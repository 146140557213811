import React from "react";
import { Col, Row, Image } from "react-bootstrap";

//Imagenes/Iconos/Logos
import inmoclick from "../../../img/grupo_8191.png";
import signo_mas from "../../../img/invalid_name.png";
import tokko from "../../../img/logo_tokko_01_1.png";
import facebook from "../../../img/icon_awesome_facebook_square.png";
import instragram from "../../../img/icon_feather_instagram.png";

import "./Navbar.css";

const Navbar = () => {
    return(<>
        <Row className="cont_navbar">
            <Col sm={4} className="cont_img_navbar">
                <Image className="img_inmoclick" src={inmoclick} />
                <Image className="img_signo_mas" src={signo_mas} />
                <Image className="img_tokko" src={tokko} />
            </Col>
            <Col sm={1}></Col>
            <Col sm={7} className="content_text_navbar">
                <a href="#beneficios" className="text_navbar"><b>BENEFICIOS</b></a>
                <a href="#caracteristicas" className="text_navbar"><b>CARACTERÍSTICAS</b></a>
                <a href="#form" className="text_navbar"><b>CONTACTO</b></a>
                <a href="https://www.facebook.com/inmoclick"><Image className="img_redes" src={facebook} /></a>
                <a href="https://www.instagram.com/inmoclick.com.ar/"><Image className="img_redes" src={instragram}/></a>
            </Col>
        </Row>
    </>)
}

export default Navbar;