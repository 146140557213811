import React from 'react';
import ReactDOM from 'react-dom';

import Routers from './Routers';

import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
    <Routers />
  ,document.getElementById('root')
);

